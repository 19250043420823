<template>
  <div class="jepaas-login">
    <div class="box_top">
      <Header />
    </div>

    <div class="jepaas-loginform">
      <!-- 登录和注册 -->
      <el-tabs
        v-if="handleType=='login'"
        v-model="activeName"
        stretch
      >
        <el-tab-pane
          label="登录"
          name="first"
        >
          <el-form
            ref="loginForm"
            :model="loginForm"
            :rules="loginRules"
            status-icon
            class="loginForm"
          >
            <el-form-item prop="phone">
              <el-input
                v-if="loginMode"
                v-model="loginForm.phone"
                placeholder="手机号"
                type="phone"
                autocomplete="off"
              />
              <el-input
                v-if="!loginMode"
                v-model="loginForm.phone"
                placeholder="手机号"
                type="phone"
                class="input-with-select"
              />
            </el-form-item>
            <el-form-item
              v-if="loginMode"
              prop="password"
            >
              <el-input
                v-model="loginForm.password"
                type="password"
                placeholder="密码"
                autocomplete="off"
                show-password
              />
            </el-form-item>
            <el-form-item
              v-if="!loginMode"
              prop="validateCode"
            >
              <el-input
                v-model="loginForm.validateCode"
                class="regPhone"
                placeholder="请输入短信验证码"
                autocomplete="off"
              >
                <el-button
                  slot="suffix"
                  :disabled="auth_time!=0"
                  @click="getCode(loginForm.phone, 'auth_time')"
                  type="text"
                  style="line-height: normal"
                >
                  {{ auth_time == 0?"获取验证码":`${auth_time}s重新发送` }}
                </el-button>
              </el-input>
            </el-form-item>
            <el-form-item style="margin-bottom: 20px;">
              <el-checkbox v-model="loginForm.rememberStatus">
                记住状态
              </el-checkbox>
              <span class="handleBtns">
                <el-button
                  @click="forgetPsd"
                  size="small"
                  type="text"
                >忘记密码</el-button>
                <el-button
                  @click="changeMode"
                  size="small"
                  type="text"
                >{{ loginMode ? '动态验证码登录' : '个人密码登录' }}</el-button>
              </span>
            </el-form-item>
            <el-form-item>
              <el-button
                :loading="isLoginLoading"
                @click="submitLogin('loginForm')"
                type="danger"
              >
                登录
              </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane
          label="注册"
          name="second"
        >
          <el-form
            ref="registerForm"
            :model="registerForm"
            :rules="registerRules"
            status-icon
            class="registerForm"
          >
            <el-form-item prop="regPhone">
              <el-input
                v-model="registerForm.regPhone"
                class="regPhone"
                type="phone"
                placeholder="手机号"
                autocomplete="off"
              >
                <el-button
                  slot="suffix"
                  :disabled="auth_timeRegister!=0"
                  @click="getCode(registerForm.regPhone, 'auth_timeRegister')"
                  type="text"
                  style="line-height: normal"
                >
                  {{ auth_timeRegister == 0?"获取验证码":`${auth_timeRegister}s重新发送` }}
                </el-button>
              </el-input>
            </el-form-item>
            <el-form-item prop="regSafeCode">
              <el-input
                v-model="registerForm.regSafeCode"
                placeholder="请输入短信验证码"
                autocomplete="off"
              />
            </el-form-item>
            <el-form-item prop="regPassword">
              <el-input
                v-model="registerForm.regPassword"
                show-password
                placeholder="密码"
                autocomplete="off"
              />
            </el-form-item>
            <el-form-item prop="regEmail">
              <el-input
                v-model="registerForm.regEmail"
                placeholder="邮箱"
                autocomplete="off"
              />
            </el-form-item>
            <el-form-item prop="name">
              <el-input
                v-model="registerForm.name"
                placeholder="姓名"
                autocomplete="off"
              />
            </el-form-item>
            <!-- <el-form-item prop="isReader">
              <el-checkbox v-model="registerForm.isReader">
                我已阅读
                <el-button
                  type="text"
                  style="padding: 0"
                >
                  &nbsp;&nbsp;服务条款
                </el-button>&nbsp;&nbsp;及
                <el-button
                  type="text"
                  style="padding: 0"
                >
                  隐私政策
                </el-button>
              </el-checkbox>
            </el-form-item> -->
            <el-form-item>
              <el-button
                :loading="isRegisterLoading"
                @click="submitRegister('registerForm')"
                type="danger"
              >
                注册
              </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <!-- 找回密码 -->
      <el-tabs
        v-if="handleType=='back'"
        v-model="backName"
        class="mdyTab"
        stretch
      >
        <el-tab-pane
          label="找回密码"
          name="back"
        >
          <el-form
            ref="backForm"
            :model="backForm"
            :rules="backRules"
            status-icon
            class="backForm"
          >
            <el-form-item prop="phone">
              <el-input
                v-model="backForm.phone"
                placeholder="手机号"
                type="phone"
                class="input-with-select"
              />
            </el-form-item>
            <el-form-item prop="validateCode">
              <el-input
                v-model="backForm.validateCode"
                class="regPhone"
                placeholder="请输入短信验证码"
                autocomplete="off"
              >
                <el-button
                  slot="suffix"
                  :disabled="auth_timeBack!=0"
                  @click="getCode(backForm.phone, 'auth_timeBack')"
                  type="text"
                  style="line-height: normal"
                >
                  {{ auth_timeBack == 0?"获取验证码":`${auth_timeBack}s重新发送` }}
                </el-button>
              </el-input>
            </el-form-item>
            <el-form-item style="text-align: right;margin-bottom: 20px;">
              <el-button
                @click="backLogin"
                type="text"
              >
                返回登录
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button
                :loading="isBackLoading"
                @click="nextStep('backForm')"
                type="danger"
              >
                下一步
              </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <!-- 设置密码 -->
      <el-tabs
        v-if="handleType=='mdy'"
        v-model="mdyName"
        class="mdyTab"
        stretch
      >
        <el-tab-pane
          label="修改密码"
          name="mdy"
        >
          <el-form
            ref="mdyForm"
            :model="mdyForm"
            :rules="mdyRules"
            status-icon
            class="mdyForm"
          >
            <el-form-item prop="password">
              <el-input
                v-model="mdyForm.password"
                type="password"
                placeholder="设置密码"
                autocomplete="off"
                show-password
              />
            </el-form-item>
            <el-form-item prop="password2">
              <el-input
                v-model="mdyForm.password2"
                type="password"
                placeholder="确认密码"
                autocomplete="off"
                show-password
              />
            </el-form-item>
            <el-form-item style="text-align: right;margin-bottom: 50px;">
              <el-button
                @click="backLogin"
                type="text"
              >
                返回登录
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button
                :loading="isMdyLoading"
                @click="doSure('mdyForm')"
                type="danger"
              >
                确认
              </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header';
import {
  POST_RANDOM,
  POST_REGISTER,
  POST_LOGIN,
  POST_GET_PASSWORD,
  POST_USER_INFO,
} from '@/pages/index/actions/api';
import rulesJs from '@/util/rulesUtil';
import { setStorage, errorCallback } from '@/util/util';
import { setToken } from '@/util/auth';

export default {
  name: 'Login',
  components: {
    Header,
  },
  data() {
    return {
      auth_time: 0, // 登录的获取验证码倒计时
      auth_timeBack: 0, // 找回密码的验证码倒计时
      auth_timeRegister: 0, // 注册的获取验证码倒计时
      activeName: 'first',
      loginForm: {
        phone: '',
        rememberStatus: true,
        password: '',
        validateCode: '',
      },
      loginRules: {
        phone: [{ validator: rulesJs.validatePhone, trigger: 'blur' }],
        password: [{ validator: rulesJs.rulesLoginPassword, trigger: 'blur' }],
        validateCode: [{ validator: rulesJs.rulesPhoneCode, trigger: 'blur' }],
      },
      registerForm: {
        regPhone: '',
        regSafeCode: '',
        regPassword: '',
        regEmail: '',
        isReader: false,
        name: '',
      },
      registerRules: {
        name: [{ validator: rulesJs.validateName, trigger: 'change' }],
        regPhone: [{ validator: rulesJs.validatePhone, trigger: 'blur' }],
        regSafeCode: [{ validator: rulesJs.rulesPhoneCode, trigger: 'blur' }],
        regPassword: [
          { validator: rulesJs.rulesLoginPassword, trigger: 'blur' },
        ],
        regEmail: [{ validator: rulesJs.rulesRegEmail, trigger: 'blur' }],
      },
      backForm: {
        phone: '',
        validateCode: '',
      },
      backRules: {
        phone: [{ validator: rulesJs.validatePhone, trigger: 'blur' }],
        validateCode: [{ validator: rulesJs.rulesPhoneCode, trigger: 'blur' }],
      },
      backName: 'back',
      mdyForm: {
        password: '',
        password2: '',
      },
      mdyRules: {
        password: [{ validator: rulesJs.rulesLoginPassword, trigger: 'blur' }],
        password2: [{ validator: rulesJs.rulesLoginPassword, trigger: 'blur' }],
      },
      mdyName: 'mdy',
      isMdyLoading: false,
      isBackLoading: false,
      isLoginLoading: false,
      isRegisterLoading: false,
      loginMode: true,
      selectPrephone: '1',
      backSelectPrephone: '1',
      handleType: 'login', // 操作类型  login=登录 back=找回密码 mdy=修改密码
    };
  },
  created() {
    const lett = this;
    document.onkeydown = function () {
      const key = window.event.keyCode;
      if (key == 13) {
        lett.submitLogin('loginForm');
      }
    };
    this.isLoginPhone(); // 判断是否保存手机号了
  },
  methods: {
    // 切换方式登录
    changeMode() {
      this.$refs.loginForm.resetFields(); // 切换之后重置掉表单的登录验证问题
      this.loginMode = !this.loginMode;
    },
    // 忘记密码
    forgetPsd() {
      // this.$refs[`loginForm`].resetFields();
      this.handleType = 'back';
    },
    // 返回登录
    backLogin() {
      // this.$refs[`loginForm`].resetFields();
      this.handleType = 'login';
    },
    // 判断登录是否记住状态了
    loginStatusNumber() {
      if (this.loginForm.rememberStatus) {
        localStorage.setItem('loginFormPhone', this.loginForm.phone);
      } else {
        // 如果不勾选记住状态的话删除之前的记录
        localStorage.getItem('loginFormPhone') && localStorage.removeItem('loginFormPhone');
      }
    },
    // 判断本地是否存储的账号
    isLoginPhone() {
      if (localStorage.getItem('loginFormPhone')) {
        this.loginForm.phone = localStorage.getItem('loginFormPhone');
      }
    },
    // 点击登录
    submitLogin(formName) {
      this.isLoginLoading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$ajax({
            url: POST_LOGIN,
            data: {
              phone: this.loginForm.phone,
              type: this.loginMode ? 'general' : 'dynamic',
              password: this.loginForm.password,
              codeDomo: this.loginForm.validateCode,
            },
          }).then((obj) => {
            // 登录成功的时候判断是否记住登录状态了
            this.loginStatusNumber();
            this.isLoginLoading = false;
            const { data } = obj;
            if (data.success) {
              // 登录状态 token 存cookie
              setToken(data.obj);
              this.getUserInfo();
            } else if (data.errorCode == '9102') {
              errorCallback(data.errorCode);
            } else {
              this.$message({
                message: `${data.message}`,
                type: 'error',
              });
            }
          });
        } else {
          this.isLoginLoading = false;
          return false;
        }
      });
    },
    // 获取用户登录信息
    getUserInfo() {
      this.$ajax({
        url: POST_USER_INFO,
        data: {},
      }).then((datas) => {
        if (datas.data.success) {
          datas.data.obj.vipState == '1' && (datas.data.obj.vipTime = datas.data.obj.vipTime.substring(0, 10));
          this.$store.commit('getUserinfo', datas.data.obj);
          setStorage('local', 'userInfo', datas.data.obj);
          // store中登录状态 置为true
          this.$store.commit('hasLogin', true);
          // 登录成功 后 返回
           this.$router.push({
            path: '/'
          })
          setTimeout(() => {
            setStorage('local', 'jepaasStore', this.$store.state);
          });
        } else {
          this.$message({
            message: `${datas.data.message}`,
            type: 'error',
          });
        }
      });
    },
    // 点击注册
    submitRegister(formName) {
      this.isRegisterLoading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 如果没有点击阅读就返回
          // if (!this.registerForm.isReader) {
          //   this.$message.error('请先勾选阅读隐私权限');
          //   this.isRegisterLoading = false;
          //   return;
          // }
          this.$ajax({
            url: POST_REGISTER,
            data: {
              phone: this.registerForm.regPhone,
              name: this.registerForm.name,
              email: this.registerForm.regEmail,
              password: this.registerForm.regPassword,
              codeDomo: this.registerForm.regSafeCode,
            },
          }).then((obj) => {
            const { data } = obj;
            // 如果后台判断不通过
            this.isRegisterLoading = false;
            if (data.errorCode) {
              this.$message({
                message: `${data.message}`,
                type: 'error',
              });
              return;
            }
            // 后台判断通过
            this.$message({
              message: '注册成功请登录',
              type: 'success',
            });
            this.activeName = 'first';
          });
        } else {
          this.isRegisterLoading = false;
          return false;
        }
      });
    },
    // 找回密码 下一步
    nextStep(formName) {
      this.isBackLoading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$ajax({
            url: POST_GET_PASSWORD,
            data: {
              phone: this.backForm.phone,
              codeDomo: this.backForm.validateCode,
            },
          }).then((obj) => {
            this.isBackLoading = false;
            const { data } = obj;
            if (data.errorCode) {
              if (data.errorCode) {
                this.$message({
                  message: `${data.message}`,
                  type: 'error',
                });
                return;
              }
            }
            this.handleType = 'mdy';
          });
        } else {
          this.isBackLoading = false;
          return false;
        }
      });
    },
    // 设置密码 确认
    doSure(formName) {
      this.isMdyLoading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$ajax({
            url: POST_GET_PASSWORD,
            data: {
              phone: this.backForm.phone,
              codeDomo: this.backForm.validateCode,
              setPassword: this.mdyForm.password,
              confirPassword: this.mdyForm.password2,
            },
          }).then((obj) => {
            const { data } = obj;
            if (data.errorCode) {
              this.$message({
                message: `${data.message}`,
                type: 'error',
              });
              return;
            }
            this.$message({
              message: '修改成功请登录',
              type: 'success',
            });
            // this.loginMode = true;
            this.$router.go(0);
            // this.isMdyLoading = false;
            // this.handleType = 'login';
          });
        } else {
          this.isMdyLoading = false;
          return false;
        }
      });
    },

    /**
     * 获取验证码
     * @param phone 当前的手机号
     * @param status 当前验证码倒计时的字段
     */
    getCode(phone, status) {
      let errMsg = '';
      //  获取验证码的判断手机号是否满足
      // 登录的正则判断
      status == 'auth_time'
        && this.$refs.loginForm.validateField('phone', (error) => {
          if (error) {
            errMsg = error;
          }
        });
      // 注册的正则判断
      status == 'auth_timeRegister'
        && this.$refs.registerForm.validateField('regPhone', (error) => {
          if (error) {
            errMsg = error;
          }
        });
      // 找回密码的正则判断
      status == 'auth_timeBack'
        && this.$refs.backForm.validateField('phone', (error) => {
          if (error) {
            errMsg = error;
          }
        });
      // 不满足任何一项全部返回
      if (!phone || this[status] != 0 || errMsg) {
        return;
      }
      this.$ajax({
        url: POST_RANDOM,
        data: {
          phone,
        },
      }).then((obj) => {
        const { data } = obj;
        if (data.errorCode) {
          this.$message({
            message: `${data.message}`,
            type: 'error',
          });
          return;
        }
        this.$message({
          message: '验证码已发送!',
          type: 'success',
        });
        this[status] = 59;
        const auth_timer = setInterval(() => {
          this[status]--;
          if (this[status] <= 0) {
            clearInterval(auth_timer);
          }
        }, 1000);
      });
    },
  },
};
</script>

<style lang='less'>
body,
html {
  margin: 0;
  padding: 0;
  background-color: #fff;
}
.jepaas-login {
  text-align: initial;
  .box_top {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
  }
  .el-tabs__active-bar {
    background-color: #f15442;
  }
  .el-button--danger {
    background-color: #f15442;
    border-color: #f15442;
  }
  .el-tabs__nav-wrap::after {
    background-color: #fff;
  }
  .jepaas-loginform {
    position: absolute;
    width: 550px;
    height: 600px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .mdyTab {
      .el-tabs__active-bar {
        height: 0;
      }
    }
    .el-tabs__item {
      padding: 10px 20px;
      height: initial;
      line-height: normal;
      font-size: 26px;
      font-weight: 400;
      color: rgba(63, 63, 63, 1);
    }
    .el-form {
      padding: 60px 0 30px;
      .el-input__inner {
        height: 50px;
        line-height: 50px;
      }
      .el-form-item:first-child {
        margin-bottom: 50px;
      }
      .el-checkbox {
        padding-left: 10px;
        .el-checkbox__input {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
      .input-with-select .el-input-group__prepend {
        background-color: #fff;
        width: 88px;
      }
      .el-input__validateIcon {
        display: none;
      }
      .handleBtns {
        float: right;
      }
      .el-button--danger {
        width: 100%;
        padding: 15px;
      }
    }
    .registerForm {
      .el-form-item:first-child {
        margin-bottom: 22px;
      }
      .el-form-item:last-child {
        margin-bottom: 0;
      }
    }
    .regPhone {
      .el-input__suffix {
        top: 50%;
        transform: translateY(-50%);
      }
      .el-input__inner {
        padding-right: 100px;
      }
    }
  }
}
</style>
